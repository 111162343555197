import cx from 'classnames'

import { SanityUSPBlock } from '@data/sanity/queries/types/blocks'

import Photo from '@components/photo'
import { backgroundColorClasses } from '@lib/color'

type USPProps = Pick<
  SanityUSPBlock,
  'variant' | 'title' | 'description' | 'icon' | 'background'
>

const USP = ({ variant, title, description, icon, background }: USPProps) => {
  const TitleTag = `h${variant === 'large' ? 3 : 4}` as React.ElementType

  return (
    <div
      className={cx(
        'flex',
        {
          'flex-row gap-4': variant === 'small',
          'flex-col gap-2.5 p-8': variant === 'large',
        },
        background ? backgroundColorClasses[background] : '',
      )}
    >
      <Photo image={icon} />

      <div
        className={cx({
          'space-y-0.5': variant === 'small',
          'space-y-2.5': variant === 'large',
        })}
      >
        <TitleTag>{title}</TitleTag>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default USP
