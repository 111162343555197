import { type SanityAccordionsBlock } from '@data/sanity/queries/types/blocks'

import Accordion from '@components/accordion'
import SimplePortableText from '@components/simple-portable-text'

type AccordionsProps = Pick<SanityAccordionsBlock, 'items'>

const Accordions = ({ items }: AccordionsProps) => {
  return (
    <div className="mb-12 last:mb-0">
      {items.map(({ _key, title, content }) => (
        <Accordion key={_key} id={_key} title={title}>
          <SimplePortableText className="rc" content={content} />
        </Accordion>
      ))}
    </div>
  )
}

export default Accordions
