import cx from 'classnames'

import { type SanityBlock } from '@data/sanity/queries/types/blocks'

import Accordions from '@blocks/accordions'
import AccountAddressDetails from '@blocks/account/account-address-details'
import AccountDetails from '@blocks/account/account-details'
import AccountOrderList from '@blocks/account/account-order-list'
import BlogPostCard from '@blocks/blog/blog-post-card'
import CollectionCarousel from '@blocks/shop/collection-carousel'
import ContentCarousel from '@blocks/content-carousel'
import CookieDeclaration from '@blocks/cookiebot/cookie-declaration'
import CTA from '@blocks/cta'
import DemoForm from '@blocks/demo-form'
import Freeform from '@blocks/freeform'
import LinkBlock from '@blocks/link-block'
import LoginForm from '@blocks/account/login-form'
import Newsletter from '@blocks/newsletter'
import PasswordRecoveryForm from '@blocks/account/password-recovery-form'
import ProductCard from '@blocks/shop/product-card'
import SignupForm from '@blocks/account/signup-form'
import TeamMemberCard from '@blocks/team-member-card'
import USP from '@blocks/usp'
import VideoModule from './video'

interface GridBlockProps {
  block: SanityBlock
}

const GridBlock = ({ block }: GridBlockProps) => {
  switch (block._type) {
    case 'accordions': {
      const { items } = block

      return <Accordions items={items} />
    }

    case 'accountAddressDetails': {
      const { active, accountAddressDetailsStrings, addressFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountAddressDetails
          accountAddressDetailsStrings={accountAddressDetailsStrings}
          addressFormStrings={addressFormStrings}
          className="mt-10 mb-10"
        />
      )
    }

    case 'accountDetails': {
      const { active, accountDetailsStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountDetails
          accountDetailsStrings={accountDetailsStrings}
          className="mb-10"
        />
      )
    }

    case 'accountOrderList': {
      const { active, accountOrderListStrings } = block

      if (!active) {
        return null
      }

      return (
        <AccountOrderList
          accountOrderListStrings={accountOrderListStrings}
          className="mb-16"
        />
      )
    }

    case 'blogPostCard': {
      const { post, options } = block

      return <BlogPostCard post={post} options={options} />
    }

    case 'collectionCarousel': {
      const { collection } = block

      return <CollectionCarousel collection={collection} />
    }

    case 'contentCarousel': {
      const { title, items } = block

      return <ContentCarousel title={title} items={items} />
    }

    case 'cookieDeclaration': {
      const { active, cookieBotId } = block

      if (!active || !cookieBotId) {
        return null
      }

      return <CookieDeclaration cookieBotId={cookieBotId} />
    }

    case 'cta': {
      const { content, image } = block

      console.log({ content })

      return <CTA content={content} image={image} />
    }

    case 'demoForm': {
      const {
        _key,
        service,
        klaviyoListID,
        klaviyoNewsletterListID,
        submit,
        analytics,
        successMsg,
        errorMsg,
        terms,
        newsletterStatement,
        buttonStyle,
        strings,
      } = block

      return (
        <DemoForm
          id={_key}
          service={service}
          klaviyoListID={klaviyoListID}
          klaviyoNewsletterListID={klaviyoNewsletterListID}
          submit={submit}
          analytics={analytics}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
          newsletterStatement={newsletterStatement}
          buttonStyle={buttonStyle}
          strings={strings}
        />
      )
    }

    case 'freeform': {
      const { content, textAlign, maxWidth } = block

      return (
        <Freeform content={content} textAlign={textAlign} maxWidth={maxWidth} />
      )
    }

    case 'iframe': {
      const { title, url, width, height } = block

      return (
        <iframe
          title={title}
          src={url}
          className={cx({ 'w-full': !width })}
          width={width}
          height={height}
        />
      )
    }

    case 'linkBlock': {
      const { title, link, image } = block

      return <LinkBlock title={title} link={link} image={image} />
    }

    case 'loginForm': {
      const { active, loginFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <LoginForm
          loginFormStrings={loginFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'newsletter': {
      const {
        _key,
        title,
        description,
        variant,
        service,
        klaviyoListID,
        submit,
        successMsg,
        errorMsg,
        terms,
      } = block

      return (
        <Newsletter
          id={_key}
          title={title}
          description={description}
          variant={variant}
          service={service}
          klaviyoListID={klaviyoListID}
          submit={submit}
          successMsg={successMsg}
          errorMsg={errorMsg}
          terms={terms}
        />
      )
    }

    case 'passwordRecoveryForm': {
      const { active, passwordRecoveryFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <PasswordRecoveryForm
          passwordRecoveryFormStrings={passwordRecoveryFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'predefinedContentBlock': {
      const { contentBlocks } = block

      return (
        <>
          {contentBlocks?.map((contentBlock) => (
            <GridBlock key={contentBlock._key} block={contentBlock} />
          ))}
        </>
      )
    }

    case 'productCard': {
      const { product } = block

      return <ProductCard product={product} showPrice />
    }

    case 'signupForm': {
      const { active, service, klaviyoListID, signupFormStrings } = block

      if (!active) {
        return null
      }

      return (
        <SignupForm
          service={service}
          klaviyoListID={klaviyoListID}
          signupFormStrings={signupFormStrings}
          className="mt-16 mb-10"
        />
      )
    }

    case 'teamMemberCard': {
      const { teamMember } = block

      return <TeamMemberCard teamMember={teamMember} />
    }

    case 'usp': {
      const { variant, title, description, icon, background } = block

      return (
        <USP
          variant={variant}
          title={title}
          description={description}
          icon={icon}
          background={background}
        />
      )
    }

    case 'video': {
      const { type, muxVideo, settings, aspectRatio, borderRadius } = block

      return (
        <VideoModule
          type={type}
          muxVideo={muxVideo}
          settings={settings}
          aspectRatio={aspectRatio}
          borderRadius={borderRadius}
        />
      )
    }
  }
}

export default GridBlock
