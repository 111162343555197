import cx from 'classnames'

import { type SanityAccountDetailsStrings } from '@data/sanity/queries/types/blocks'
import { type UserCompany, type UserCustomer, useUser } from '@lib/auth'
import { getLinkPageUrl } from '@lib/routes'

import ButtonLink, { ButtonVariant } from '@components/buttons/button-link'

interface AccountDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  className?: string
}

interface AccountCustomerDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  customer?: UserCustomer
}

interface AccountCompanyDetailsProps {
  accountDetailsStrings: SanityAccountDetailsStrings
  company?: UserCompany
}

const AccountCustomerDetails = ({
  accountDetailsStrings,
  customer,
}: AccountCustomerDetailsProps) => {
  if (!customer) {
    return null
  }

  return (
    <div className="space-y-4">
      <h4>{accountDetailsStrings.accountAccountDetailsHeading}</h4>

      <p>
        {`${customer.firstName} ${customer.lastName}`}
        <br />

        {customer.phone}
        <br />

        {customer.email}
      </p>

      <ButtonLink
        variant={ButtonVariant.LINK}
        href={getLinkPageUrl('accountAddressPage')}
      >
        {accountDetailsStrings.accountViewAddresses}
      </ButtonLink>
    </div>
  )
}

const AccountCompanyDetails = ({
  accountDetailsStrings,
  company,
}: AccountCompanyDetailsProps) => {
  if (!company) {
    return null
  }

  return (
    <div className="space-y-4">
      <h4>{accountDetailsStrings.accountOrganizationDetailsHeading}</h4>

      <p>
        {company.name}
        <br />

        {[accountDetailsStrings.accountOrganizationVat, company.vatNumber]
          .filter(Boolean)
          .join(' ')}
        <br />

        {company.email}
        <br />

        {[
          accountDetailsStrings.accountOrganizationContactPerson,
          company.contactPerson,
        ]
          .filter(Boolean)
          .join(': ')}
        <br />

        {company.country}
      </p>
    </div>
  )
}

const AccountDetails = ({
  accountDetailsStrings,
  className,
}: AccountDetailsProps) => {
  const { user } = useUser()

  if (!user) {
    return null
  }

  return (
    <div className={cx('flex flex-wrap gap-x-24 gap-y-7', className)}>
      <AccountCustomerDetails
        customer={user.customer}
        accountDetailsStrings={accountDetailsStrings}
      />
      <AccountCompanyDetails
        company={user.company}
        accountDetailsStrings={accountDetailsStrings}
      />
    </div>
  )
}

export default AccountDetails
