import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  type FormEvent,
  useContext,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import { type SanityNewsletterBlock } from '@data/sanity/queries/types/blocks'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { addEmailToNewsletterList } from '@lib/services'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import ComplexPortableText from '@components/complex-portable-text'
import InputField from '@components/input-field'
import SimplePortableText from '@components/simple-portable-text'

type NewsletterProps = Omit<SanityNewsletterBlock, '_key' | '_type'> & {
  id: string
  className?: string
  isInlineButton?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms?: boolean
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const Newsletter = ({
  id,
  service,
  klaviyoListID,
  variant,
  title,
  description,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isInlineButton,
}: NewsletterProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const { handleSubmit, register, watch, reset } =
    useForm<NewsletterFormValues>()
  const hasAgreed = terms ? watch('acceptTerms') : true
  const email = watch('email')
  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: emailRegex,
      message: strings.emailInvalid,
    },
  })
  const acceptTermsRegister = register('acceptTerms')

  const resetForm = (event: FormEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (
    newsletterFormValues: NewsletterFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    setIsError(false)
    setIsSuccess(false)

    const listId = service === 'klaviyo' ? klaviyoListID : null

    // Set an error if there is no list supplied
    if (!listId) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms is required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)

    try {
      await addEmailToNewsletterList(
        locale,
        service,
        listId,
        newsletterFormValues.email,
        newsletterFormValues.fullname,
      )
      triggerAnalyticsEvent(AnalyticsEventName.NewsletterSignUp)
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  const isExtended = variant === 'extended'
  const isDisabled = isSubmitting || !hasAgreed || !emailRegex.test(email)

  if (!service) {
    return null
  }

  const form = (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <input
              type="text"
              autoComplete="off"
              className="hidden"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
            />

            {isExtended ? (
              <input
                id={`newsletter-${id}-email`}
                type="email"
                onChange={emailRegister.onChange}
                onBlur={emailRegister.onBlur}
                ref={emailRegister.ref}
                name={emailRegister.name}
                placeholder={strings.emailAddress}
                className={cx(
                  'p-4 mb-5 rounded-full w-full max-w-[300px] sm:max-w-[400px] text-pageText',
                )}
              />
            ) : (
              <InputField
                id={`newsletter-${id}-email`}
                type="email"
                formRegister={emailRegister}
                placeholder={strings.emailAddress}
                borderBottom
                className="mb-4"
              >
                {isInlineButton && (
                  <Button
                    id={`newsletter-${id}-submit`}
                    type="submit"
                    size={ButtonSize.SMALL}
                    disabled={isDisabled}
                    className="ml-3 text-xs whitespace-nowrap uppercase"
                  >
                    {submit}
                  </Button>
                )}
              </InputField>
            )}

            {terms && (
              <Checkbox
                id={`newsletter-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                className="mb-4"
              >
                <SimplePortableText
                  className="rc rc-checkbox"
                  content={terms}
                />
              </Checkbox>
            )}

            {!isInlineButton && (
              <Button
                id={`newsletter-${id}-submit`}
                type="submit"
                size={ButtonSize.NORMAL}
                color={ButtonColor.DEFAULT}
                variant={ButtonVariant.FILLED}
                disabled={isDisabled}
                className={cx({
                  'w-full mt-3': !isExtended,
                  'w-auto uppercase mt-1': isExtended,
                })}
              >
                {submit}
              </Button>
            )}
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({
              'max-w-[400px] mx-auto': isExtended,
            })}
          >
            <Alert>
              <ComplexPortableText
                content={successMsg}
                className="rc rc-alert"
              />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className={cx({
              'max-w-[400px] mx-auto': isExtended,
            })}
          >
            <Alert
              buttonText={strings.buttonTryAgain}
              onClick={resetForm}
              buttonColor={ButtonColor.DEFAULT}
            >
              <ComplexPortableText content={errorMsg} className="rc rc-alert" />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )

  if (isExtended) {
    return (
      <div className="bg-pageText text-pageBG py-8 xs:py-10 md:py-12 lg:py-20 px-5 xs:px-10 flex flex-col justify-center items-center rounded-md">
        {title && <h4 className="text-center mb-4 max-w-4xl">{title}</h4>}

        {description && (
          <p className="text-base text-center max-w-3xl">{description}</p>
        )}
        <div className="w-full text-center mt-8">{form}</div>
      </div>
    )
  }

  return form
}

export default Newsletter
