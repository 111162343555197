import { useCallback, useContext, useEffect, useState } from 'react'

import { GET_CUSTOMER } from '@data/shopify/storefront/queries/customer'
import {
  type GetCustomerOrdersQuery,
  type GetCustomerQuery,
  type GetCustomerQueryVariables,
  type MailingAddressFragmentFragment,
  type OrderFinancialStatus,
  type OrderFragmentFragment,
  type OrderFulfillmentStatus,
} from '@data/shopify/storefront/types'
import { type StoredUser, useUser } from './auth'
import { getLocaleVariable } from './environment'
import { type Locale } from './language'
import { getSanityClient } from './sanity/client'
import { getCustomerByEmail } from './sanity/customer'
import { ShopContext } from './shop-context'
import { parseMailingAddressValues } from './shopify/address'
import {
  getShopifyDomain,
  getShopifyStorefrontApiUrl,
  parseShopifyGlobalId,
} from './shopify/client'
import {
  type ShopifyClient,
  getShopifyStorefrontClient,
} from './shopify/graphql/client'
import { getShopifyUserOrders } from './shopify/graphql/customer'
import { parseOrderNode } from './shopify/order'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

export interface UserAddress {
  id: string
  globalId?: number
  isDefault: boolean
  formatted: string[]
  values: AddressFormValues
}

export interface UserOrder {
  id: string
  date: string
  paymentStatus: OrderFinancialStatus | null
  fulfillmentStatus: OrderFulfillmentStatus
  total: number
  url: string
}

export interface UserProduct {
  id: number
  title: string
  slug: string
}

const listPageSize = 10

/**
 * Gets user orders from Shopify order query result.
 */
const parseCustomerOrders = ({ customer }: GetCustomerOrdersQuery) => {
  const orderNodes = (customer?.orders?.edges
    ?.map((edge) => edge.node as OrderFragmentFragment | undefined)
    ?.filter(Boolean) ?? []) as OrderFragmentFragment[]

  return orderNodes
    .sort(
      (orderNode1, orderNode2) =>
        Date.parse(orderNode2.processedAt) - Date.parse(orderNode1.processedAt),
    )
    .map(parseOrderNode)
}

/**
 * User order list hook.
 */
export const useUserOrderList = () => {
  const { shopifyStorefrontClient } = useContext(ShopContext)
  const { user } = useUser()

  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const loadOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    const shopifyUserOrders = await getShopifyUserOrders(
      shopifyStorefrontClient,
      user.token,
      orderCursor,
      listPageSize,
    )
    setOrders((orders) => [
      ...(orders ?? []),
      ...parseCustomerOrders(shopifyUserOrders),
    ])
    setOrderCursor(
      shopifyUserOrders.customer?.orders?.edges
        ?.map(({ cursor }) => cursor)
        ?.pop() ?? null,
    )
    setHasMoreOrders(
      shopifyUserOrders.customer?.orders.pageInfo.hasNextPage ?? false,
    )

    setIsLoadingOrders(false)
  }, [orderCursor, shopifyStorefrontClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      // Clear orders
      setOrders([])
      setOrderCursor(null)
      setHasMoreOrders(false)
      setIsInitialized(false)
    }
  }, [isInitialized, loadOrders, user?.token])

  return {
    orders,
    hasMoreOrders,
    loadOrders,
    isLoadingOrders,
  }
}

/**
 * Gets Shopify user addresses.
 */
const getShopifyUserAddresses = async (
  shopifyStorefrontClient: ShopifyClient,
  userToken: string,
) => {
  try {
    const getCustomerResult = await shopifyStorefrontClient.query<
      GetCustomerQuery,
      GetCustomerQueryVariables
    >({
      query: GET_CUSTOMER,
      variables: {
        customerAccessToken: userToken,
      },
    })

    return getCustomerResult.data
  } catch (error) {
    console.log(error)

    return {
      customer: null,
    }
  }
}

/**
 * Parses Shopify user address results.
 */
const parseCustomerAddresses = (result: GetCustomerQuery) => {
  const defaultAddressId = result.customer?.defaultAddress?.id
  const addresses =
    result.customer?.addresses?.edges?.map((edge) => {
      if (!edge.node) {
        return
      }

      const addressFragment = edge.node as MailingAddressFragmentFragment

      return {
        id: addressFragment.id,
        globalId: parseShopifyGlobalId(addressFragment.id),
        isDefault: addressFragment.id === defaultAddressId,
        formatted: addressFragment.formatted,
        values: parseMailingAddressValues(addressFragment, defaultAddressId),
      }
    }) ?? []

  return addresses.filter(Boolean) as UserAddress[]
}

/**
 * Gets user address and product details.
 */
export const getUserDetails = async (
  locale: Locale,
  user: StoredUser,
): Promise<StoredUser> => {
  if (!user.token || !user.email) {
    return {
      isLoggedIn: false,
    }
  }

  const newUser: StoredUser = {
    ...user,
  }

  // Get user data from Sanity
  const sanityClient = getSanityClient()

  // Customer and company
  const customer = await getCustomerByEmail(sanityClient, user.email)

  if (!!customer) {
    newUser.customer = {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phone: customer.phone,
    }
  }

  if (!!customer?.company) {
    newUser.company = {
      name: customer.company.name,
      vatNumber: customer.company.vatNumber,
      email: customer.company.email,
      contactPerson: customer.company.contactPerson,
      country: customer.company.country,
      percentDiscount: customer.company.percentDiscount,
    }
  }

  // Get user data from Shopify
  const shopifyDomain = getShopifyDomain(locale)
  const shopifyStorefrontAccessToken = getLocaleVariable(
    locale,
    'NEXT_PUBLIC_SHOPIFY_API_TOKEN',
  )

  if (!!shopifyDomain && !!shopifyStorefrontAccessToken) {
    const shopifyStorefrontApiUrl = getShopifyStorefrontApiUrl(shopifyDomain)
    const shopifyStorefrontClient = getShopifyStorefrontClient(
      shopifyStorefrontApiUrl,
      shopifyStorefrontAccessToken,
    )

    // User addresses
    const shopifyUserAddresses = await getShopifyUserAddresses(
      shopifyStorefrontClient,
      user.token,
    )
    newUser.addresses = parseCustomerAddresses(shopifyUserAddresses)
  }

  return newUser
}
