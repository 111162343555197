import { type MailingAddressFragmentFragment } from '@data/shopify/storefront/types'
import { type AddressFormValues } from '@lib/user'

/**
 * Parses Shopify mailing address into address form values.
 */
export const parseMailingAddressValues = (
  address: MailingAddressFragmentFragment,
  defaultAddressId?: string,
): AddressFormValues => ({
  firstName: address.firstName ?? '',
  lastName: address.lastName ?? '',
  company: address.company ?? '',
  address1: address.address1 ?? '',
  address2: address.address2 ?? '',
  city: address.city ?? '',
  country: address.country ?? '',
  zip: address.zip ?? '',
  phone: address.phone ?? '',
  isDefault: address.id === defaultAddressId,
})
