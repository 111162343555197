import { useContext, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import {
  type SanityAccountAddressDetailsStrings,
  type SanityAddressFormStrings,
} from '@data/sanity/queries/types/blocks'
import { useDeleteAddress } from '@lib/shopify/graphql/customer-address'
import { fadeAnimation } from '@lib/animate'
import { useUser } from '@lib/auth'
import { defaultCountryCodes, englishCountryNames } from '@lib/country'
import { LanguageContext } from '@lib/language-context'
import { StringsContext } from '@lib/strings-context'
import { type AddressFormValues } from '@lib/user'

import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import AddressForm, { AddressFormMode } from './address-form'

interface AccountAddressDetailsProps {
  className?: string
  accountAddressDetailsStrings: SanityAccountAddressDetailsStrings
  addressFormStrings: SanityAddressFormStrings
}

const AccountAddressDetails = ({
  accountAddressDetailsStrings,
  addressFormStrings,
  className,
}: AccountAddressDetailsProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const [formMode, setFormMode] = useState<AddressFormMode>(
    AddressFormMode.CREATE,
  )
  const [addressId, setAddressId] = useState('')
  const [showForm, setShowForm] = useState(false)

  const initialValues: AddressFormValues = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: englishCountryNames[defaultCountryCodes[locale]],
    zip: '',
    phone: '',
    isDefault: false,
  }
  const [formDefaultValues, setFormDefaultValues] =
    useState<AddressFormValues>(initialValues)

  const { user } = useUser()
  const deleteAddress = useDeleteAddress()

  const token = user?.token
  const addresses = user?.addresses ?? []

  if (!token) {
    return null
  }

  const showCreateForm = () => {
    setFormMode(AddressFormMode.CREATE)
    setFormDefaultValues(initialValues)
    setAddressId('')
    setShowForm(true)
  }

  const showEditForm = (id: string, values: AddressFormValues) => {
    setFormMode(AddressFormMode.EDIT)
    setFormDefaultValues(values)
    setAddressId(id)
    setShowForm(true)
  }

  return (
    <div className={className}>
      <AnimatePresence mode="wait">
        {showForm && (
          <motion.div
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <AddressForm
              addressFormStrings={addressFormStrings}
              mode={formMode}
              hide={() => setShowForm(false)}
              addressId={addressId}
              defaultValues={formDefaultValues}
            />
          </motion.div>
        )}

        {!showForm && (
          <motion.div
            key="list"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
            className="flex flex-wrap gap-x-24 gap-y-12"
          >
            <div className="max-w-64 w-full">
              <h4 className="mb-4">
                {accountAddressDetailsStrings.accountAddAddressHeading}
              </h4>

              <Button
                variant={ButtonVariant.FILLED}
                onClick={showCreateForm}
                className="min-w-48"
              >
                {accountAddressDetailsStrings.accountAddAddress}
              </Button>
            </div>

            <div className="flex flex-wrap gap-24 gap-y-8">
              {addresses
                .sort((a, b) => (b.isDefault ? 1 : 0) - (a.isDefault ? 1 : 0))
                .map((address) => (
                  <div key={address.id} className="flex flex-col">
                    {address.isDefault && (
                      <h4 className="mb-4">
                        {
                          accountAddressDetailsStrings.accountDefaultAddressLabel
                        }
                      </h4>
                    )}

                    <div className="flex-grow">
                      {address.formatted.map((addressLine) => (
                        <p key={addressLine}>{addressLine}</p>
                      ))}
                    </div>

                    <div className="flex gap-5 mt-8">
                      <Button
                        variant={ButtonVariant.OUTLINED}
                        color={ButtonColor.GRAY}
                        size={ButtonSize.SMALL}
                        onClick={() => showEditForm(address.id, address.values)}
                        className="min-w-24"
                      >
                        {strings.buttonEdit}
                      </Button>

                      <button
                        className="text-sm font-medium hover:opacity-60"
                        onClick={() => deleteAddress(address.id, token)}
                      >
                        {strings.buttonDelete}
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default AccountAddressDetails
