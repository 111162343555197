import axios, { type AxiosResponse } from 'axios'

import { type Locale } from './language'

export interface KlaviyoBookADemoPayload {
  listId: string
  email: string
  phoneNumber: string
  firstName: string
  lastName: string
  company?: string
}

export interface KlaviyoBookADemoResponse {
  error?: string
}

export interface KlaviyoNewsletterJoinPayload {
  listId: string
  name?: string
  email: string
}

export interface KlaviyoNewsletterJoinResponse {
  error?: string
}

export interface KlaviyoWaitlistJoinPayload {
  variantId: number
  email: string
  name?: string
}

export interface KlaviyoWaitlistJoinResponse {
  error?: string
}

type BookADemoResponse = KlaviyoBookADemoResponse

type NewsletterJoinResponse = KlaviyoNewsletterJoinResponse

type WaitlistJoinResponse = KlaviyoWaitlistJoinResponse

const getApiUrl = (service: string, action: string) =>
  `/api/${service.toLowerCase()}/${action}`

const getApiHeaders = (locale: Locale): Record<string, any> => ({
  'Content-Type': 'application/json',
  'X-Locale': locale,
})

const callApi = async <T>(
  url: string,
  payload: any,
  headers: Record<string, any>,
) =>
  await axios.post<T, AxiosResponse<T>, string>(url, JSON.stringify(payload), {
    headers,
  })

/**
 * Calls newsletter signup API page.
 */
export const addEmailToNewsletterList = async (
  locale: Locale,
  service: string,
  listId: string,
  email: string,
  name?: string,
) => {
  const url = getApiUrl(service, 'newsletter-join')
  const payload =
    service === 'klaviyo'
      ? ({
          listId,
          email,
          name,
        } satisfies KlaviyoNewsletterJoinPayload)
      : null
  const headers = getApiHeaders(locale)
  await callApi<NewsletterJoinResponse>(url, payload, headers)
}

/**
 * Calls waitlist signup API page.
 */
export const addEmailToWaitlist = async (
  locale: Locale,
  service: string,
  variantId: number,
  email: string,
  name?: string,
) => {
  const url = getApiUrl(service, 'waitlist-join')
  const payload =
    service === 'klaviyo'
      ? ({
          variantId,
          email,
          name,
        } satisfies KlaviyoWaitlistJoinPayload)
      : null
  const headers = getApiHeaders(locale)
  await callApi<WaitlistJoinResponse>(url, payload, headers)
}

/**
 * Calls demo booking API page.
 */
export const bookADemo = async (
  locale: Locale,
  service: string,
  listId: string,
  email: string,
  phoneNumber: string,
  firstName: string,
  lastName: string,
  company?: string,
) => {
  const url = getApiUrl(service, 'book-a-demo')
  const payload =
    service === 'klaviyo'
      ? ({
          listId,
          email,
          phoneNumber,
          firstName,
          lastName,
          company,
        } satisfies KlaviyoBookADemoPayload)
      : null
  const headers = getApiHeaders(locale)
  await callApi<BookADemoResponse>(url, payload, headers)
}
