import { type SanityCollectionCarouselBlock } from '@data/sanity/queries/types/blocks'

import ProductCard from '@blocks/shop/product-card'
import Carousel from '@components/carousel'

type CollectionCarouselProps = Pick<SanityCollectionCarouselBlock, 'collection'>

const CollectionCarousel = ({ collection }: CollectionCarouselProps) => {
  return (
    <Carousel
      hasArrows
      items={collection.products.map((product, index) => {
        const { galleryPhotos, listingPhotos, productID } = product

        return (
          <div key={`${index}-${productID}`}>
            <ProductCard product={product} showPrice />
          </div>
        )
      })}
    />
  )
}

export default CollectionCarousel
