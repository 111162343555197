import { type FetchResult } from '@apollo/client'

import { customerUpdateMutation } from '@data/shopify/admin/mutations/customer'
import {
  type CustomerInput,
  type CustomerUpdateMutation,
  type CustomerUpdateMutationVariables,
} from '@data/shopify/admin/types'
import {
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_ACCESS_TOKEN_DELETE,
  CUSTOMER_CREATE,
  CUSTOMER_RECOVER,
} from '@data/shopify/storefront/mutations/customer'
import {
  type CustomerAccessTokenCreateMutation,
  type CustomerAccessTokenCreateMutationVariables,
  type CustomerAccessTokenDeleteMutation,
  type CustomerAccessTokenDeleteMutationVariables,
  type CustomerCreateInput,
  type CustomerCreateMutation,
  type CustomerCreateMutationVariables,
  type CustomerRecoverMutation,
  type CustomerRecoverMutationVariables,
  type GetCustomerOrdersQuery,
  type GetCustomerOrdersQueryVariables,
  CustomerErrorCode,
} from '@data/shopify/storefront/types'
import { GET_CUSTOMER_ORDERS } from '@data/shopify/storefront/queries/customer'
import { type StoredUser } from '@lib/auth'
import { type ErrorMessages } from '@lib/helpers'
import {
  type ParseResults,
  type ShopifyClient,
  getGrqphQLErrorFieldName,
  parseMutationResult,
  ParseStatus,
} from './client'

interface UserAccessTokenResponse {
  loginUserResult: ParseResults
  user?: StoredUser
}

interface CreateCustomerResult {
  errors: ErrorMessages
  id?: string
}

interface UpdateCustomerResult {
  errors: ErrorMessages
  id?: string
}

/**
 * Creates a new Shopify customer.
 */
export const createCustomer = async (
  shopifyStorefrontClient: ShopifyClient,
  values: CustomerCreateInput,
): Promise<CreateCustomerResult> => {
  try {
    const customerCreateResult = await shopifyStorefrontClient.mutate<
      CustomerCreateMutation,
      CustomerCreateMutationVariables
    >({
      mutation: CUSTOMER_CREATE,
      variables: {
        input: values,
      },
    })

    const errors: ErrorMessages = {}
    customerCreateResult.data?.customerCreate?.customerUserErrors?.forEach(
      (customerUserError, index) => {
        const fieldName = getGrqphQLErrorFieldName(customerUserError.field)
        errors[fieldName ?? index] = customerUserError.message
      },
    )

    return {
      id: customerCreateResult.data?.customerCreate?.customer?.id,
      errors,
    }
  } catch (error) {
    console.log(error)

    return {
      errors: {
        0: `${error}`,
      },
    }
  }
}

/**
 * Updates a new Shopify customer.
 */
export const updateCustomer = async (
  shopifyAdminClient: ShopifyClient,
  values: CustomerInput,
): Promise<UpdateCustomerResult> => {
  try {
    const customerUpdateResult = await shopifyAdminClient.mutate<
      CustomerUpdateMutation,
      CustomerUpdateMutationVariables
    >({
      mutation: customerUpdateMutation,
      variables: {
        input: values,
      },
    })

    const errors: ErrorMessages = {}
    customerUpdateResult.data?.customerUpdate?.userErrors?.forEach(
      (userError, index) => {
        const fieldName = getGrqphQLErrorFieldName(userError.field)
        errors[fieldName ?? index] = userError.message
      },
    )

    return {
      id: customerUpdateResult.data?.customerUpdate?.customer?.id,
      errors,
    }
  } catch (error) {
    console.log(error)

    return {
      errors: {
        0: `${error}`,
      },
    }
  }
}

/**
 * Gets customer login validation results.
 */
const parseCustomerAccessTokenCreateResult = (
  customerAccessTokenCreateResult: FetchResult<CustomerAccessTokenCreateMutation>,
): ParseResults => {
  const customerUserErrors =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerUserErrors ?? []
  const results: ParseResults = {
    fieldErrors: {},
    errorCount: customerUserErrors.length,
    status: ParseStatus.OK,
  }

  customerUserErrors.forEach((customerUserError) => {
    const fieldName = getGrqphQLErrorFieldName(customerUserError.field)

    if (fieldName) {
      results.fieldErrors[fieldName] = customerUserError.message
    }

    if (customerUserError.code === CustomerErrorCode.UnidentifiedCustomer) {
      results.status = ParseStatus.INVALID_CREDENTIALS
    }
  })

  return results
}

/**
 * Creates a new user access token from credentials.
 */
export const createUserAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string,
  password: string,
): Promise<UserAccessTokenResponse> => {
  // Create user token
  const customerAccessTokenCreateResult = await shopifyStorefrontClient.mutate<
    CustomerAccessTokenCreateMutation,
    CustomerAccessTokenCreateMutationVariables
  >({
    mutation: CUSTOMER_ACCESS_TOKEN_CREATE,
    variables: {
      input: {
        email,
        password,
      },
    },
  })
  const loginUserResult = parseCustomerAccessTokenCreateResult(
    customerAccessTokenCreateResult,
  )

  if (loginUserResult.status !== 'ok' || loginUserResult.errorCount > 0) {
    return { loginUserResult }
  }

  const token =
    customerAccessTokenCreateResult.data?.customerAccessTokenCreate
      ?.customerAccessToken?.accessToken ?? ''
  const user: StoredUser = {
    isLoggedIn: true,
    email,
    token,
  }

  return {
    loginUserResult,
    user,
  }
}

/**
 * Deletes a user access token.
 */
export const deleteUserAccessToken = async (
  shopifyStorefrontClient: ShopifyClient,
  customerAccessToken: string,
) => {
  // Delete user token
  await shopifyStorefrontClient.mutate<
    CustomerAccessTokenDeleteMutation,
    CustomerAccessTokenDeleteMutationVariables
  >({
    mutation: CUSTOMER_ACCESS_TOKEN_DELETE,
    variables: {
      customerAccessToken,
    },
  })
}

/**
 * Sends a password recovery email.
 */
export const recoverUserPassword = async (
  shopifyStorefrontClient: ShopifyClient,
  email: string,
) => {
  try {
    const customerRecoverResult = await shopifyStorefrontClient.mutate<
      CustomerRecoverMutation,
      CustomerRecoverMutationVariables
    >({
      mutation: CUSTOMER_RECOVER,
      variables: { email },
    })

    return parseMutationResult(
      customerRecoverResult.data?.customerRecover?.customerUserErrors ?? [],
    )
  } catch (error) {
    console.log(error)

    return {
      fieldErrors: {},
      errorCount: 0,
      status: ParseStatus.UNKNOWN_ERROR,
    }
  }
}

/**
 * Gets customer orders from Shopify.
 */
export const getShopifyUserOrders = async (
  shopifyStorefrontClient: ShopifyClient,
  customerAccessToken: string,
  afterCursor: string | null,
  orderLimit = 10,
) => {
  try {
    const getCustomerOrdersResult = await shopifyStorefrontClient.query<
      GetCustomerOrdersQuery,
      GetCustomerOrdersQueryVariables
    >({
      query: GET_CUSTOMER_ORDERS,
      variables: {
        customerAccessToken,
        orderLimit,
        afterCursor,
      },
    })

    return getCustomerOrdersResult.data
  } catch (error) {
    console.log(error)

    return { customer: null }
  }
}
