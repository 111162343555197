import { type OrderFragmentFragment } from '@data/shopify/storefront/types'
import { type UserOrder } from '@lib/user'

/**
 * Parses Shopify customer order node.
 */
export const parseOrderNode = ({
  name,
  processedAt,
  financialStatus,
  fulfillmentStatus,
  totalPriceV2,
  statusUrl,
}: OrderFragmentFragment): UserOrder => ({
  id: name,
  date: processedAt,
  paymentStatus: financialStatus ?? null,
  fulfillmentStatus: fulfillmentStatus,
  total: Number(totalPriceV2.amount),
  url: statusUrl,
})
