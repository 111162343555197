import cx from 'classnames'

import { SanityCTABlock } from '@data/sanity/queries/types/blocks'

import Photo from '@components/photo'
import ComplexPortableText from '@components/complex-portable-text'

type CTAProps = Pick<SanityCTABlock, 'content' | 'image'>

const CTA = ({ content, image }: CTAProps) => (
  <div className="relative flex items-center min-h-96">
    <Photo
      image={image}
      fill
      className="after:bg-black after:absolute after:inset-0 after:opacity-40"
      imageClassName="object-cover"
    />

    <ComplexPortableText
      content={content}
      className="rc text-center text-white max-w-xl mx-auto relative z-1 py-10 px-5"
    />
  </div>
)

export default CTA
